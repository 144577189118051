/**
 * ItemRow.js
 * Creates and returns a card for an item (user, session, or video)
 */

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import React, { Component } from "react";
import * as admin from "firebase/app";
import _ from "lodash";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";
import { typeToLabel } from "./ListItems";
import { elasticIndex, reportIndex } from "../../../../common/elastic";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

//Object of converters that convert from elastic data to display data

const reportFields = {
  users: ["fullName", "email", "userCreated", "role"],
  sessions: [
    "sessionName",
    "sessionDate",
    "sessionEnv",
    "windSpeed",
    "weatherDesc",
    "temperature",
  ],
  videos: [
    "videoOrigName",
    "videoPath",
    "videoCreated",
    "videoLength",
    "videoType",
    "videoSource",
    "reviewed",
    "videoSize",
    "metadata",
  ],
  analysis: [
    "createdDate",
    "swingConfidenceScore",
    "swingScore",
    "isAnalysisSuccessful, isVideoUploaded",
    "isSessionUploaded",
  ],
  subscriptions: [
    "startDate",
    "endDate",
    "lastUpdated",
    "userEmail",
    "userId",
    "productId",
    "platform",
  ],
  invites: ["senderId", "createdAt", "status", "lastUpdated"],
  partnerSubscriptions: [
    "startDate",
    "endDate",
    "lastUpdated",
    "userEmail",
    "userId",
    "productId",
  ],
};

async function requestElastic(index, endpoint, body) {
  body.data = { data: body.data, index: index, endpoint: endpoint };
  const response = await axiosWithToken(
    functionBaseUrl + "/api/elastic",
    body
  ).catch((err) => {
    console.log(err.response ? err.response.data : err);
    throw err;
  });
  return response.data;
}

function modifyCollectionData(data, collection, id, forReport = false) {
  let extras = {};
  if (collection === "users") {
    extras = {
      docType: "users",
      fullName: data.firstName + " " + data.lastName,
      doc_relations: {
        name: "users",
      },
    };
    //Convert dob to milliseconds, place old values in dobStr
    const date_regex =
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (typeof data.dob === "string") {
      if (date_regex.test(data.dob)) {
        data.dobStr = data.dob;
        data.dob = new Date(data.dob).getTime();
        data.dob = data.dob < 0 ? 0 : data.dob;
      } else {
        data.dob = 0;
      }
    }
  } else if (collection === "sessions") {
    data.sessionDate = parseInt(data.sessionDate);
    extras = {
      docType: "sessions",
      doc_relations: {
        name: "sessions",
        parent: data.parentUserId,
      },
    };
  } else if (collection === "videos") {
    // Turn videoLength, videoSize, fps, intrinsic and distortion into numbers
    data.videoLength = parseInt(data.videoLength);
    data.videoSize = parseInt(data.videoSize);
    data.metaData.fps = isNaN(data.metaData.fps) ? 0 : data.metaData.fps;
    data.metaData.intrinsic = parseFloat(data.metaData.intrinsic);
    data.intrinsicVerbose = JSON.stringify(data.metaData.intrinsic);
    data.metaData.distortion = parseFloat(data.metaData.distortion);
    data.metaData.isLowLightModeEnabled = Boolean(data.metaData.isLowLightModeEnabled);
    extras = {
      docType: "videos",
      doc_relations: {
        name: "videos",
        parent: data.parentSessionId,
      },
    };
  } else if (collection === "analysis") {
    data.swingScore = parseFloat(data.swingScore);
    data.swingScore =
      data.swingScore > 1 ? data.swingScore / 100 : data.swingScore;
    data.swingConfidenceScore = parseFloat(data.swingConfidenceScore);
    data.swingConfidenceScore =
      data.swingConfidenceScore > 1
        ? data.swingConfidenceScore / 100
        : data.swingConfidenceScore;
    extras = {
      docType: "analysis",
      doc_relations: {
        name: "analysis",
        parent: data.videoId,
      },
    };
  } else if (collection === "reportIssues") {
    data.reviewed = Boolean(data.reviewed);
    data.urgent = Boolean(data.urgent);
    data.swingConfidenceScore = Boolean(data.swingConfidenceScore);
  } else if (collection === "invites") {
    extras = {
      docType: "invites",
      doc_relations: {
        name: "invites",
        parent: data.senderId,
      },
    };
  } else if (collection === "subscriptions") {
    data.startDate = parseInt(data.startDate);
    data.endDate = parseInt(data.endDate);
    data.lastUpdated = parseInt(data.lastUpdated);
    data.autoRenewal = Boolean(data.autoRenewal);
    data.bootcampUser = Boolean(data.bootcampUser);
    extras = {
      docType: "subscriptions",
      doc_relations: {
        name: "subscriptions",
        parent: data.userId,
      },
    };
  } else if (collection === "payments") {
    extras = {
      docType: "payments",
      doc_relations: {
        name: "payments",
        parent: data.subscriptionId,
      },
    };
  } else if (collection === "userPosts") {
    extras = {
      docType: "userPosts",
    };
  } else if (collection === "partnerSubscriptions") {
    data.startDate = parseInt(data.startDate);
    data.endDate = parseInt(data.endDate);
    data.lastUpdated = parseInt(data.lastUpdated);
    data.autoRenewal = Boolean(data.autoRenewal);
    data.isAddOn = Boolean(data.isAddOn);
    extras = {
      docType: "partnerSubscriptions",
    };
  }

  if (forReport && reportFields.hasOwnProperty(collection)) {
    const newData = {};
    reportFields[collection].forEach((field) => {
      newData[field] = data[field];
    });
    newData.id = id;
    data = newData;
  }

  if (extras.doc_relations?.parent === "" && !forReport) {
    console.log("Document with empty parent id: " + id + "... skipping");
    return null;
  } else if (forReport) {
    delete extras.doc_relations;
    return { ...data, ...extras };
  } else {
    return { ...data, ...extras };
  }
}

async function setReportChild(data) {
  const promises = [];
  const keys = [
    ["userId", "users"],
    ["sessionId", "sessions"],
    ["videoId", "videos"],
    ["subscriptionId", "subscriptions"],
    ["inviteId", "invites"],
  ];
  keys.forEach((keyPair) => {
    if (data[keyPair[0]]) {
      const p = new Promise((resolve) => {
        //For each child document, get that document and add it to the report doc
        admin
          .firestore()
          .collection(keyPair[1])
          .doc(data[keyPair[0]])
          .get()
          .then((docSnap) => {
            const docData = docSnap.data();
            if (docData) {
              data[keyPair[1]] = modifyCollectionData(
                docData,
                keyPair[1],
                docSnap.id,
                true
              );
              //If the child doc is a video, add any associated analysis as well
              if (keyPair[1] === "videos") {
                const analysisSnap = admin
                  .firestore()
                  .collection("analysis")
                  .where("videoId", "==", docSnap.id);
                analysisSnap
                  .get()
                  .then((analysisData) => {
                    const analysisOutput = [];
                    //Modify document data for the analysis before appending it to object
                    analysisData.forEach((analysisDoc) => {
                      analysisOutput.push(
                        modifyCollectionData(
                          analysisDoc.data(),
                          "analysis",
                          analysisDoc.id,
                          true
                        )
                      );
                    });
                    data["analysis"] = analysisOutput;
                    resolve(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    resolve(false);
                  });
              } else {
                resolve(true);
              }
            } else {
              //If a document no longer exists, log that in elastic_error
              data.elastic_error = data.elastic_error ? data.elastic_error : [];
              data.elastic_error.push({
                type: "missing",
                collection: keyPair[1],
                id: data[keyPair[0]],
              });
              resolve(false);
            }
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      });
      promises.push(p);
    }
  });
  return Promise.all(promises);
}

const stageMaps = {
  idle: { label: () => `Idle` },
  loadingDocs: { label: () => `Loading Documents from Firebase` },
  loadingReportChild: {
    label: (data) =>
      `Loading Child Documents for Reports (${data.completed}/${data.total})`,
  },
  generatingBulk: {
    label: (data) =>
      `Generating Request For ${data.count}/${data.total} Documents`,
  },
  sendingRequest: {
    label: (data) => `Sending Request (${data.completed}/${data.total})`,
  },
  done: { label: () => `Done` },
};

class AdminActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: "idle",
      collection: "users",
      stageData: {},
      requestConfirmation: false,
      indexByTimestamp: false,
      dateRange: {
        startDate: Date.now(),
        endDate: Date.now(),
        key: "selection",
      },
    };
  }
  sendBulk = async (snap, collection, index, maxSize = 2000) => {
    let dataWChildren = [];
    if (collection === "reportIssues") {
      let reportCurr = 0;
      const reportPromises = [];
      for (let doc of snap.docs) {
        reportPromises.push(
          new Promise((resolve) => {
            const data = modifyCollectionData(
              doc.data(),
              collection,
              doc.id,
              collection === "reportIssues"
            );
            setReportChild(data)
              .then(() => {
                console.log("Loaded doc data");
                reportCurr++;
                this.setState({
                  stage: "loadingReportChild",
                  stageData: { completed: reportCurr, total: snap.size },
                });
                resolve(data);
              })
              .catch((err) => {
                console.log(err, data);
                resolve(null);
              });
          })
        );
      }
      console.log("Waiting for document children to load");
      dataWChildren = (await Promise.all(reportPromises)).filter(
        (data) => data
      );
      console.log("Loaded document children");
    }

    let bulk = [];
    let promises = [];
    let curr = 0;
    let completed = 0;
    let total = Math.ceil(snap.size / maxSize);
    const snaps = collection === "reportIssues" ? dataWChildren : snap.docs;
    for (let doc of snaps) {
      promises.push(
        new Promise(async (resolve) => {
          ++curr;
          //If the document is a report, the data has already been retrieved so skip the modification step
          let data =
            collection === "reportIssues"
              ? doc
              : modifyCollectionData(
                  doc.data(),
                  collection,
                  doc.id,
                  collection === "reportIssues"
                );
          bulk = bulk.concat([
            { index: { _index: index, _id: doc.id, routing: 1 } },
            data,
          ]);
          //If the length is greater than or equal to the max size or it is the last document, send the bulk request and reset the bulk
          if (bulk.length >= maxSize * 2 || curr === snap.size) {
            console.log(`Sending ${bulk.length / 2} documents`);
            const data =
              bulk.map((item) => JSON.stringify(item)).join("\n") + "\n";
            bulk = [];
            resolve(
              await requestElastic(index, "_bulk", {
                method: "POST",
                data: data,
                headers: {
                  "Content-Type": "application/x-ndjson",
                },
              })
                .then((response) => {
                  completed++;
                  console.log(`Completed ${completed}/${total} Batches`);
                  this.setState({
                    stage: "sendingRequest",
                    stageData: { completed, total },
                  });
                  return response;
                })
                .catch((err) => {
                  console.log(err.response?.data ? err.response.data : err);
                  return err.response?.data ? err.response.data : err;
                })
            );
          }
          if (curr % 100 === 0) {
            this.setState({
              stage: "generatingBulk",
              stageData: { count: curr, total: snap.size },
            });
          }
          resolve(null);
        })
      );
    }
    this.setState({ stage: "sendingRequest", stageData: { completed, total } });
    let res = await Promise.all(promises);
    //Filter out all null entries
    res = res.filter((item) => item);
    console.log(res);
    this.setState({ stage: "done" });
  };
  indexCollection = async () => {
    const collection = this.state.collection;
    const index = collection === "reportIssues" ? reportIndex : elasticIndex;
    this.setState({ stage: "loadingDocs", requestConfirmation: false });

    const dateField = {
      users: { value: "userCreated", type: "number" },
      sessions: { value: "sessionDate", type: "string" },
      videos: { value: "videoCreated", type: "number" },
      analysis: { value: "createdDate", type: "string" },
      reportIssues: { value: "createdDate", type: "string" },
      subscriptions: { value: "startDate", type: "string" },
      invites: { value: "createdAt", type: "number" },
      payments: { value: "paymentDate", type: "string" },
      userPosts: { value: "createdAt", type: "number" },
    }[this.state.collection];

    //Get snapshot of collection
    let snap;

    if (this.state.indexByTimestamp) {
      snap = await admin
        .firestore()
        .collection(collection)
        .where(
          dateField.value,
          ">=",
          dateField.type === "string"
            ? `${moment(this.state.dateRange.startDate).valueOf()}`
            : +moment(this.state.dateRange.startDate).valueOf()
        )
        .where(
          dateField.value,
          "<=",
          dateField.type === "string"
            ? `${moment(this.state.dateRange.endDate).valueOf()}`
            : +moment(this.state.dateRange.endDate).valueOf()
        )
        .get();
    } else {
      snap = await admin.firestore().collection(collection).get();
    }
    console.log("Got snapshot of size ", snap.size);
    this.setState({
      stage: "generatingBulk",
      stageData: { count: 0, total: snap.size },
    });
    await this.sendBulk(snap, collection, index);
    console.log(`Indexed ${snap.size} documents`);
    setTimeout(() => this.setState({ stage: "idle" }), 5000);
  };

  // indexWithTimestamp = async () => {
  //   console.log(this.state.collection);
  //   const index =
  //     this.state.collection === "reportIssues" ? reportIndex : elasticIndex;

  //   await axiosWithToken(
  //     functionBaseUrl + "/api/elastic/reindex-with-timeframe",
  //     {
  //       method: "POST",
  //       data: {
  //         index,
  //         collection: this.state.collection,
  //         from:,
  //         to: ,
  //         dateField,
  //       },
  //     }
  //   );
  // };

  requestConfirmation = () => {
    this.setState({ requestConfirmation: true });
  };

  handleDateRange = (e) => {
    console.log(e);
    this.setState({ dateRange: e.selection });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            <Typography variant="h3">Admin Actions</Typography>

            <Typography variant="subtitle2">
              Utility items for managing elastic indices
            </Typography>
          </div>

          <Typography>Indexing</Typography>
          <Divider />
          <div
            style={{
              display: this.state.stage === "idle" ? "flex" : "none",
              alignContent: "center",
            }}
          >
            <TextField
              label="Collection"
              select
              value={this.state.collection}
              onChange={(e) => this.setState({ collection: e.target.value })}
              margin="dense"
              variant="outlined"
            >
              {Object.entries(typeToLabel).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            {this.state.requestConfirmation ? (
              <>
                <Button onClick={this.indexCollection}>Yes</Button>
                <Button
                  onClick={() => {
                    this.setState({ requestConfirmation: false });
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  this.setState({ requestConfirmation: true });
                }}
              >
                Index
              </Button>
            )}
          </div>

          <div>
            <Checkbox
              value={this.state.indexByTimestamp}
              onChange={(e) =>
                this.setState({ indexByTimestamp: e.target.checked })
              }
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <span>Index by the date range</span>
          </div>

          {this.state.indexByTimestamp && (
            <DateRangePicker
              ranges={[this.state.dateRange]}
              onChange={this.handleDateRange}
            />
          )}

          <Typography
            style={{
              display: this.state.requestConfirmation ? "block" : "none",
            }}
            color="error"
          >
            Indexing may take several minutes to complete, are you sure you want
            to start indexing?
          </Typography>
          <div
            style={{ display: this.state.stage !== "idle" ? "block" : "none" }}
          >
            <Typography>
              {stageMaps[this.state.stage].label(this.state.stageData)}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AdminActions;
