import React, { useCallback, useEffect, useState } from "react";
import s from "./style.module.css";
import { MainPageSwings } from "./components/MainPageSwings/MainPageSwings";
import {
  ForesightCard,
  IntroductionCard,
} from "./components/IntroductionCard/IntroductionCard";
import { MainPageInvitations } from "./components/MainPageInvitations/MainPageInvitations";
import {
  checkNewAccount,
  getDashboardSwingCounts,
  getDashboardUser,
  getExpiringSubscriptions,
  getInvites,
  getInvitesReceived,
  getNotifications,
  removePendingStudent,
  removeStudent,
  resendInvite,
  upgradeStudent,
  verifyStaffEnt,
} from "./api";
import { ActivityList } from "./components/ActivityList/ActivityList";
import { MainPageSubscriptions } from "./components/MainPageSubscriptions/MainPageSubscriptions";
import { listOfSubscriptions } from "../../common/envConfig";
import Swal from "sweetalert2";
import { UpgradeStudentDialog } from "./components/UpgradeStudentDialog/UpgradeStudentDialog";
import NoSubscriptionDialog from "../../components/NoSubscriptionDialog";
import Integration from "../student_dashboard/Integration";
import NewAccountCheck from "../dashboard/NewAccountCheck";
import BootcampCheck from "../dashboard/BootcampCheck";
import { useHistory } from "react-router-dom";

export const MainPage = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
  });
  const [invites, setInvites] = useState([]);
  const [invitesReceived, setInvitesReceived] = useState([]);
  const [activity, setActivity] = useState([]);
  const [activityOffset, setActivityOffset] = useState(0);
  const [activityTotal, setActivityTotal] = useState(0);

  const [expiringSubscriptions, setExpiringSubscriptions] = useState([]);
  const [swings, setSwings] = useState({
    last24Hours: 0,
    last7Days: 0,
    lastMonth: 0,
    allTime: 0,
  });
  const [upgradeDialog, setUpgradeDialog] = useState({
    open: false,
    studentDetails: null,
    form: {
      subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
      subPeriod: 1,
      autoRenewal: false,
    },
  });
  const [introCard, setIntroCard] = useState(true);
  const [integrationPopUpOpen, setIntegrationPopUpOpen] = useState(false);
  const [isStaffEnterprise, setIsStaffEnterprise] = useState(false);
  const [isCertified, setIsCertified] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [newAccPopUpOpen, setNewAccPopUpOpen] = useState(false);
  const [bootcampOpen, setBootcampOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const userData = await getDashboardUser();
      setUser({ ...userData });

      if (window.location.href.toLowerCase().includes("partner=golfgenius")) {
        setIntegrationPopUpOpen(true);
      }

      if (!isStudent(userData?.subscriptionType)) {
        const invitesData = await getInvites();
        setInvites(invitesData);
        const expiringSubscriptionData = await getExpiringSubscriptions();
        setExpiringSubscriptions(expiringSubscriptionData);
      }
      const invitesData = await getInvitesReceived(userData?.email);
      setInvitesReceived(invitesData);
      const {
        swingsCount,
        swingsLast24Hours,
        swingsLast7Days,
        swingsLastMonth,
      } = await getDashboardSwingCounts();
      setSwings({
        last24Hours: swingsLast24Hours,
        last7Days: swingsLast7Days,
        lastMonth: swingsLastMonth,
        allTime: swingsCount,
      });
      if (
        userData?.subscriptionType.includes("enterprise_master") &&
        userData?.enterpriseAccountId
      ) {
        const enterpriseData = await verifyStaffEnt(userData?.id);
        setIsStaffEnterprise(enterpriseData?.isStaffEnterprise);
        setIsCertified(enterpriseData?.isCertified);
        setOnboardingCompleted(enterpriseData?.onboardingCompleted);
      }
      const newAccount = await checkNewAccount(userData?.id);
      if (newAccount) {
        if (newAccount?.data?.bootcampUser) {
          setBootcampOpen(true);
        } else {
          setNewAccPopUpOpen(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const notificationsData = await getNotifications(activityOffset);
      setActivity((prev) => [...prev, ...notificationsData.data]);
      setActivityTotal(notificationsData.totalNumberOfElements);
    })();
  }, [activityOffset]);

  const isStudent = (subType) => {
    if (subType?.includes("student")) return true;
    else return false;
  };

  const handleUpgradeDialog = (studentDetails) => {
    setUpgradeDialog({
      open: true,
      studentDetails,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleUpgradeDialogClose = () => {
    setUpgradeDialog({
      open: false,
      studentDetails: null,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleUpgradeDialogForm = (field, value) => {
    setUpgradeDialog((prev) => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }));
  };

  const handleInviteResend = useCallback(async (id) => {
    await resendInvite(id);
    const invitesData = await getInvites();
    setInvites(invitesData);
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 2000,
      customClass: {
        container: "my-swal",
      },
    });
  }, []);

  const handleUpgradeDialogSubmit = useCallback(async () => {
    let message = "Student upgraded successfully!";
    if (upgradeDialog.open) {
      message = await upgradeStudent({
        studentId: upgradeDialog.studentDetails.id,
        coachId: user.id,
        coachName: user.firstName + " " + user.lastName,
        subPlan: upgradeDialog.form.subPlan,
        subPeriod: upgradeDialog.form.subPeriod,
        autoRenewal: upgradeDialog.form.autoRenewal,
      });
    }
    handleUpgradeDialogClose();
    Swal.fire({
      icon: "info",
      text: message,
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
      customClass: {
        container: "my-swal",
      },
    });
    setTableOptions((prev) => ({ ...prev }));
  }, [upgradeDialog, user]);

  const handlePendingStudentRemove = useCallback(async (id) => {
    await removePendingStudent(id);
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 2000,
      customClass: {
        container: "my-swal",
      },
    });
    setTableOptions((prev) => ({ ...prev }));
  }, []);

  return (
    <div className={s.pageContainer}>
      <div className={s.content}>
        <div className={s.header}>
          <h1>Dashboard</h1>
          <h2>Get an overview of your account activity.</h2>
        </div>
        <div className={s.contentContainer}>
          {(introCard && !user?.subscriptionType?.includes('free')) ? (
            <ForesightCard onClose={() => setIntroCard(false)} />
          ) : (
            <></>
          )}
          {!isStudent(user?.subscriptionType) ? (
            <>
              <MainPageSubscriptions
                onUpgrade={handleUpgradeDialog}
                data={expiringSubscriptions}
                tableOptions={tableOptions}
              />
              <MainPageInvitations
                data={invites}
                tableOptions={tableOptions}
                onResendInvite={handleInviteResend}
                onRemoveInvite={handlePendingStudentRemove}
              />
            </>
          ) : (
            <></>
          )}
          <MainPageSwings count={swings} />
        </div>
      </div>

      {!isStudent(user?.subscriptionType) ? (
        <div className={s.activity}>
          <ActivityList
            data={activity}
            total={activityTotal}
            onScroll={() => {
              setActivityOffset((prev) => prev + 10);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <UpgradeStudentDialog
        availableCredits={user && user.creditCount ? user.creditCount : 0}
        open={upgradeDialog.open}
        form={upgradeDialog.form}
        studentDetails={upgradeDialog.studentDetails}
        onClose={handleUpgradeDialogClose}
        onSubmit={handleUpgradeDialogSubmit}
        onFormChange={handleUpgradeDialogForm}
      />

      <NoSubscriptionDialog
        activeSub={user?.sub?.endDate <= Date.now() ? false : true}
        role={user?.role}
        subscriptionType={user?.subscriptionType}
        isStaffEnterprise={isStaffEnterprise}
        isCertified={isCertified}
        onboardingCompleted={onboardingCompleted}
        loading={true}
      />
      {integrationPopUpOpen && (
        <Integration
          open={integrationPopUpOpen}
          onClose={() => setIntegrationPopUpOpen(false)}
          subscriptionType={user?.subscriptionType}
          uid={user?.id}
        />
      )}
      {user && "userConsent" in user && (
        <NewAccountCheck
          open={newAccPopUpOpen}
          onClose={() => setNewAccPopUpOpen(false)}
          invitesReceived={invitesReceived}
          subscriptionType={user?.subscriptionType}
          name={user?.userName}
          firstName={user?.firstName}
          lastName={user?.lastName}
          email={user?.email}
          uid={user?.id}
          subData={user?.sub}
          userData={user}
          fullName={`${user?.firstName} ${user?.lastName}`}
          history={history}
        />
      )}
      {user && bootcampOpen && user?.sub && (
        <BootcampCheck
          open={bootcampOpen}
          onClose={() => setBootcampOpen(false)}
          subscriptionType={user?.subscriptionType}
          name={user?.userName}
          firstName={user?.firstName}
          lastName={user?.lastName}
          email={user?.email}
          uid={user?.id}
          subData={user?.sub}
          userData={user}
          history={history}
        />
      )}
    </div>
  );
};
