import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import firebase, { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import NoSubscriptionDialog from "../NoSubscriptionDialog";

const RouteWithLayout = (props) => {
    const {
        layout: Layout,
        component: Component,
        allowedRoles,
        ...rest
    } = props;
    const pageExceptions = [
        "/sign-in",
        "/sign-up",
        "/welcome",
        "/offer",
        "/invitational",
        "/swing-analysis-offer",
        "/golfpad",
        "/metagolfclub",
        "/dollar-driver-club",
        "/ccgolf",
        "/promo-checkout",
        "/promo-checkout/",
        "/golf-genius",
        "/golf-genius/open-session",
        "/golf-genius/open-session/",
        "/golf-genius/start-session",
        "/golf-genius/start-session/",
        "/2023",
        "/not-found",
        "/terms-of-service",
        "/privacy",
        "/forgot-password",
        "/custom-token-auth",
        "/plans",
        "/speed-assessment-human",
        "/speed-assessment-ai",
    ];

    const [values, setValues] = useState({
        loading: true,
        exempted: false,
    });
    const [currentUser, setCurrentUser] = useState({});
    const [currentUserData, setCurrentUserData] = useState({});
    const [subData, setSubData] = useState({});

    const loadUser = async (uid) => {
        if (uid) {
            const res = await axiosWithToken(`${functionBaseUrl}/api/users/${uid}`, {
                method: "GET",
            });
            setCurrentUserData(res.data);
            const subRes = !res?.data?.subscriptionType?.includes('free') ? await axiosWithToken(
                `${functionBaseUrl}/api/subscriptionByUserId/${uid}`,
                {
                  method: "get",
                }
              ) : { data: {} };
            setSubData(subRes.data.data);
        }
    };

    useEffect(() => {
        loadUser(currentUser?.uid);
    }, [currentUser]);

    useEffect(() => {
        if (!values.loading) {
            const user = firebase.auth().currentUser;
            setCurrentUser(user);
        }
    }, [values]);

    return (
        <Route
            {...rest}
            render={(matchProps) => {
                let { loading, exempted } = values;
                const user = firebase.auth().currentUser;
                //Allows pages to render if they are pages that dont need to be signed in for i.e. sign-in sign-up etc.
                //Email verification must have the user logged in so it has an additional requirement
                if (
                    pageExceptions.includes(matchProps.location.pathname) ||
                    (user &&
                        matchProps.location.pathname === "/email-verification")
                ) {
                    loading = false;
                    exempted = true;
                }
                //If the page requires the user to be logged in and the firebase.auth().currentUser has not updated
                if (loading) {
                    if (!user) {
                        firebase.auth().onAuthStateChanged((user) => {
                            setValues({ loading: false }); //Will reload the page once the currentUser has been updated.
                        });
                    } else {
                        setValues({ loading: false });
                    }
                    return (
                        <Layout>
                            <Grid style={{ textAlign: "center", padding: 200 }}>
                                <CircularProgress />
                            </Grid>
                        </Layout>
                    );
                }
                if (exempted || user) {
                    //If the user is logged in or the page doesn't need the user to be logged in
                    //If logged in user is not verified always reroute to email-verification
                    if (
                        user &&
                        !pageExceptions.includes(matchProps.location.pathname) &&
                        user.emailVerified === false &&
                        matchProps.location.pathname !== "/email-verification"
                    ) {
                        return <Redirect to="/email-verification" />;
                    }
                    if (
                        pageExceptions.includes(matchProps.location.pathname) ||
                        (user &&
                            matchProps.location.pathname ===
                                "/email-verification") ||
                        !allowedRoles ||
                        allowedRoles?.includes(currentUserData?.role)
                    ) {
                        return (
                            <Layout>
                                {/*!pageExceptions.includes(matchProps.location.pathname) && Object.keys(subData).length > 0 && subData.endDate <= Date.now() && <NoSubscriptionDialog
                                    activeSub={subData.endDate <= Date.now() ? false : true}
                                    role={currentUserData?.role}
                                    subscriptionType={currentUserData?.subscriptionType}
                                    isStaffEnterprise={false}
                                    isCertified={false}
                                    onboardingCompleted={true}
                                    loading={false}
                                />*/}
                                <Component {...matchProps} />
                            </Layout>
                        );
                    } else {
                        if (currentUserData?.role) {
                            return <Redirect to="/dashboard" />;
                        }
                    }
                } else {
                    alert("You are not signed in");
                    return <Redirect to="/sign-in" />;
                }
            }}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default RouteWithLayout;
