/**
 * ItemRow.js
 * Creates and returns a card for an item (user, session, or video)
 */

import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Checkbox,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    TextField,
  } from "@material-ui/core";
  import { KeyboardDatePicker } from "@material-ui/pickers";
  import {
    BarChart,
    VideoLibrary,
    SaveAlt,
    MoreVert,
    Delete,
    Edit,
    Check,
    Close,
    GroupAdd,
  } from "@material-ui/icons";
  import AssessmentIcon from "@material-ui/icons/Assessment";
  import React, { Component } from "react";
  import _ from "lodash";
  import { FieldMaps, MAX_FIELD_LENGTH } from "./AdvancedSearch";
  import { green, pink } from "@material-ui/core/colors";
  import FolderIcon from "@material-ui/icons/Folder";
  
  const titleCase = (strArr) => {
    if (strArr === undefined) return "";
    if (typeof strArr === "string") {
      strArr = strArr.split(" ");
    }
    return strArr
      .map((str) => {
        if (!str) {
          return "";
        }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      })
      .join(" ");
  };
  
  const defaultConverters = {
    date: (val) =>
      val ? new Date(parseInt(val)).toLocaleDateString("en-US") : "",
    float: (val) =>
      (typeof val === "number" ? val : parseFloat(val))
        .toFixed(3)
        .replace("NaN", ""),
    bool: (val) =>
      val ? (
        <Check style={{ color: green[500] }} />
      ) : (
        <Close style={{ color: pink[500] }} />
      ),
  };
  
  //Object of converters that convert from elastic data to display data
  const fieldConverters = {
    users: {
      userCreated: defaultConverters.date,
      gender: (val) => titleCase(val),
      students: (val) => val.length || 0,
      sessionIds: (val) => val.length || 0,
      coaches: (val) => val.length || 0,
    },
    sessions: {
      sessionDate: defaultConverters.date,
      sessionEnv: (val) =>
        FieldMaps.sessions.sessionEnv.options[val]
          ? FieldMaps.sessions.sessionEnv.options[val]
          : val,
      sessionType: (val) =>
        FieldMaps.sessions.sessionType.options[val]
          ? FieldMaps.sessions.sessionType.options[val]
          : val,
      videoIds: (val) => val.length || 0,
    },
    videos: {
      videoCreated: defaultConverters.date,
      videoSize: (val) => (val / 1000000).toFixed(2).replace("NaN", ""),
      videoLength: (val) => (val / 1000).toFixed(2).replace("NaN", "") + "s",
      videoType: (val) => val.toLowerCase(),
      analyzed: defaultConverters.bool,
      "metaData.isLowLightModeEnabled": defaultConverters.bool,
    },
    analysis: {
      swingConfidenceScore: defaultConverters.float,
      swingScore: defaultConverters.float,
      createdDate: defaultConverters.date,
    },
    reportIssues: {
      createdDate: defaultConverters.date,
      urgent: defaultConverters.bool,
      reviewed: defaultConverters.bool,
    },
    subscriptions: {
      startDate: defaultConverters.date,
      lastUpdated: defaultConverters.date,
      endDate: defaultConverters.date,
      autoRenewal: defaultConverters.bool,
      bootcampUser: defaultConverters.bool,
    },
    invites: {
      createdAt: defaultConverters.date,
      expiredIn: defaultConverters.date,
      lastUpdated: defaultConverters.date,
    },
    payments: {
      paymentDate: defaultConverters.date,
      amount: (val) => (val / 100).toFixed(2).replace("NaN", ""),
    },
    userPosts: {
      createdAt: defaultConverters.date,
      updatedAt: defaultConverters.date,
    },
    partnerSubscriptions: {
      startDate: defaultConverters.date,
      lastUpdated: defaultConverters.date,
      endDate: defaultConverters.date,
      autoRenewal: defaultConverters.bool,
      isAddOn: defaultConverters.bool,
    },
  };
  //Utility to only use converter if it exists
  const converter = (type, field, val) =>
    fieldConverters[type][field] ? fieldConverters[type][field](val) : val;
  
  class ItemRow extends Component {
    constructor(props) {
      super(props);
      this.state = {
        deleted: false,
        anchor: null,
        editing: false,
        newObj: {},
        changedFields: {},
      };
    }
  
    //Updates if the loading has changed, headers have changed, or if the item is being edited
    shouldComponentUpdate(nextProps, nextState) {
      const id = this.props.item?.[this.props.target]?._id;
      const ret =
        (!nextProps.loading &&
          !_.isEqual(this.props.headCells, nextProps.headCells)) ||
        this.props.changed[id] ||
        this.state.editing !== nextState.editing ||
        this.props.editAll !== nextProps.editAll ||
        !_.isEqual(this.state, nextState) ||
        !_.isEqual(this.props.item, nextProps.item) ||
        Boolean(this.props.selected[id]) !== Boolean(nextProps.selected[id]);
      return ret;
    }
  
    //Updater for when the item is being edited
    handleFieldChange = (type, field, val) => {
      this.props.handleChangedField(
        this.props.item[this.props.target]._id,
        field,
        val,
        this.props.item[this.props.target][field]
      );
    };
  
    //Enables editing
    handleEdit = () => {
      this.setState({ editing: true, anchor: null });
    };
  
    //Submits any changes that were made
    handleConfirm = () => {
      const id = this.props.item[this.props.target]._id;
      if (this.props.changed[id]) {
        this.props.updateItem(id, this.props.changed[id]);
      }
      this.setState({ editing: false });
    };
  
    //Deletes changes and reverts to old data
    handleCancel = () => {
      const id = this.props.item[this.props.target]._id;
      this.props.cancelEdit(id);
      this.setState({ editing: false });
    };
  
    handleDelete = (id) => {
      this.setState({ deleted: id, anchor: null });
      this.props.deleteItem(id, this.props.target);
    };
  
    //Validates field based on elastic mappings
    validateField = (field, val) => {
      const fieldSplit = field.split(".");
      const mapping = _.get(this.props.mappings, fieldSplit.join(".properties."));
      if (mapping) {
        if (
          ["integer", "unsigned_long", "double", "float", "long"].includes(
            mapping.type
          )
        ) {
          return !isNaN(val);
        }
      }
      return true;
    };
  
    //Returns a entry field depending on the field type for editing
    getCell = (headCell, obj) => {
      const [type, field] = headCell.split(":");
      let val;
      // let val = _.get(this.state.newObj[type], field) !== undefined ? _.get(this.state.newObj[type], field) : _.get(obj[type], field)
      let array = null;
      if (
        Array.isArray(obj[type]) &&
        obj[type].length > 0 &&
        type === "analysis"
      ) {
        obj[type].sort((a, b) => b.createdDate - a.createdDate);
        array = obj[type].map((item) =>
          converter(type, field, _.get(item, field))
        );
        val = this.props.changed[obj[type]._id][field]
          ? this.props.changed[obj[type]._id][field]
          : _.get(obj[type][0], field);
      } else if (obj[type]) {
        val =
          type === this.props.target && this.props.changed[obj[type]._id]?.[field]
            ? this.props.changed[obj[type]._id][field]
            : _.get(obj[type], field);
      }
      if (!(this.state.editing || this.props.editAll)) {
        if (FieldMaps[type][field]?.type === "link") {
          return val ? <a href={val}>{val}</a> : "";
        } else if (FieldMaps[type][field]?.type === "video") {
          return (
            <video
              style={{ height: 200, width: 200 }}
              allowFullScreen
              controls
              muted={true}
            >
              <source src={val} type="video/mp4" />
            </video>
          );
        } else {
          const maxLength = FieldMaps[type][field]?.maxLength || MAX_FIELD_LENGTH;
          let text = converter(type, field, val);
          if (text && text.length > maxLength) {
            return (
              <Tooltip
                title={<Typography style={{ fontSize: 14 }}>{text}</Typography>}
              >
                <p>{text.substring(0, maxLength) + "..."}</p>
              </Tooltip>
            );
          } else if (array) {
            return (
              <Tooltip
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {JSON.stringify(array)}
                  </Typography>
                }
              >
                <p>{text}</p>
              </Tooltip>
            );
          } else {
            return text;
          }
        }
      } else if (type === this.props.target) {
        const id = obj[type]._id;
        if (FieldMaps[type][field]?.type === "select") {
          return (
            <TextField
              select
              margin="dense"
              value={val}
              onChange={(e) =>
                this.handleFieldChange(type, field, e.target.value)
              }
            >
              {Object.entries(FieldMaps[type][field]?.options).map(
                ([key, label]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                )
              )}
              {/*<MenuItem key={'none'} value={''}>None</MenuItem>*/}
            </TextField>
          );
        } else if (FieldMaps[type][field]?.type === "dateRange") {
          return (
            <KeyboardDatePicker
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              InputAdornmentProps={{ position: "start" }}
              value={new Date(parseInt(val))}
              onChange={(date) =>
                this.handleFieldChange(type, field, date.getTime())
              }
              InputProps={{
                style: {
                  fontSize: 12,
                },
              }}
              KeyboardButtonProps={{ size: "small", style: { marginRight: -5 } }}
            />
          );
        } else if (FieldMaps[type][field]?.type === "bool") {
          return (
            <Checkbox
              checked={val}
              onChange={(e) =>
                this.handleFieldChange(type, field, e.target.checked)
              }
            />
          );
        } else if (field === "fullName") {
          let firstName = this.props.changed[id]?.firstName
            ? this.props.changed[id].firstName
            : _.get(obj[type], "firstName");
          let lastName = this.props.changed[id]?.lastName
            ? this.props.changed[id].lastName
            : _.get(obj[type], "lastName");
          return (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  margin="dense"
                  value={firstName}
                  onChange={(e) =>
                    this.handleFieldChange(type, "firstName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  margin="dense"
                  value={lastName}
                  onChange={(e) =>
                    this.handleFieldChange(type, "lastName", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          );
        } else if (!FieldMaps[type][field]?.adminLock || this.props.isAdmin) {
          return (
            <TextField
              fullWidth
              error={
                !this.validateField(field, _.get(this.props.changed[id], field))
              }
              margin="dense"
              value={val}
              onChange={(e) =>
                this.handleFieldChange(type, field, e.target.value)
              }
            />
          );
        }
      }
    };
  
    addFileExtension(fileName, fileType) {
      if (
        fileName.split(".").at(-1).toLowerCase() !== fileType.toLowerCase() &&
        ["mov", "mp4"].includes(fileType.toLowerCase())
      ) {
        return fileName + "." + fileType.toLowerCase();
      } else {
        return fileName;
      }
    }
  
    //Returns the row for the item
    getItem = (obj, headCells) => {
      const { videos, reportIssues, users } = obj;
      const id = obj?.[this.props.target]?._id;
  
      const actions = [];
      if (this.props.target === "users") {
        actions.push(
          <Tooltip key="show-coaches" title="Show coaches">
            <IconButton onClick={() => this.props.openCoachesPopUp(id)}>
              <GroupAdd />
            </IconButton>
          </Tooltip>
        );
      }
  
      if (
        (this.props.target === "videos" && videos) ||
        this.props.target === "reportIssues"
      ) {
        actions.push(
          <Tooltip key="export" title="Download Video">
            <IconButton
              onClick={() =>
                videos && videos.videoOrigName
                  ? this.props.downloadFileUrl(
                      this.addFileExtension(
                        videos.videoOrigName,
                        videos.videoType
                      ),
                      videos.videoPath
                    )
                  : this.props.downloadFileUrl("video.mp4", reportIssues.videoURL)
              }
            >
              <SaveAlt />
            </IconButton>
          </Tooltip>
        );
        if (this.props.target === "reportIssues" && videos) {
          actions.push(
            <Tooltip key="checkSwingData" title="Check Swing Data">
              <IconButton onClick={() => this.props.checkSwingData(videos)}>
                <FolderIcon />
              </IconButton>
            </Tooltip>
          );
        }
  
        if (this.props.target === "videos") {
          if (this.props.isAdmin) {
            actions.push(
              <Tooltip key="analyze" title="Analyze Video">
                <IconButton
                  onClick={() => this.props.analyzeVideo(videos, "user")}
                >
                  <BarChart />
                </IconButton>
              </Tooltip>
            );
            /*actions.push(<Tooltip key="analyze" title="QA Analyze Video">
                      <IconButton onClick={()=>this.props.analyzeVideo(videos, 'qa')}>
                          <AssessmentIcon/>
                      </IconButton>
                      </Tooltip>)*/
            if (videos.analyzed) {
              actions.push(
                <Tooltip key="unity" title="Preview in 3D">
                  <IconButton onClick={() => this.props.openPopUp(videos)}>
                    <VideoLibrary />
                  </IconButton>
                </Tooltip>
              );
              actions.push(
                <Tooltip key="checkSwingData" title="Check Swing Data">
                  <IconButton onClick={() => this.props.checkSwingData(videos)}>
                    <FolderIcon />
                  </IconButton>
                </Tooltip>
              );
              actions.push(
                <Tooltip key="generateReport" title="Generate Report">
                  <IconButton onClick={() => this.props.generateReport(videos)}>
                    <AssessmentIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          }
        }
      }
  
      return (
        <>
          <TableRow hover role="checkbox" key={id}>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={Boolean(this.props.selected[id])}
                onClick={this.props.handleSelect(id, obj)}
              />
            </TableCell>
            <TableCell
              padding="none"
              style={{
                display:
                  this.props.target === "videos" ||
                  this.props.target === "reportIssues" ||
                  this.props.target === "users"
                    ? "table-cell"
                    : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    headCells.includes("videos:videoPath") ||
                    headCells.includes("reportIssues:videoURL")
                      ? "column"
                      : "row",
                }}
              >
                {actions}
              </div>
            </TableCell>
            {headCells.map((headCell, index) => {
              return (
                <TableCell key={index}>{this.getCell(headCell, obj)}</TableCell>
              );
            })}
            <TableCell
              padding="none"
              style={{ paddingRight: 10 }}
              onClick={
                this.state.editing
                  ? null
                  : (event) => this.setState({ anchor: event.currentTarget })
              }
            >
              {this.state.editing ? (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Save">
                    <IconButton onClick={this.handleConfirm}>
                      <Check fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton onClick={this.handleCancel}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <MoreVert fontSize="small" />
              )}
            </TableCell>
          </TableRow>
          <Menu
            anchorEl={this.state.anchor}
            open={Boolean(this.state.anchor)}
            onClose={() => this.setState({ anchor: null })}
          >
            <MenuItem onClick={this.handleEdit}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              style={{
                display: ["users", "reportIssues"].includes(this.props.target)
                  ? "none"
                  : "",
              }}
              onClick={() => this.handleDelete(id)}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
        </>
      );
    };
    render() {
      let content;
      try {
        content = this.getItem(this.props.item, this.props.headCells);
      } catch (e) {
        content = (
          <TableCell>
            <Typography>Failed to Parse Document</Typography>
          </TableCell>
        );
        console.log(e);
        console.log(this.props.item);
      }
      return content;
    }
  }
  
  export default ItemRow;