import React, { useEffect, useState } from "react";
import s from "./page.module.css";
import { AccountDetailsTabs } from "./components/AccountDetailsTabs/AccountDetailsTabs";
import { AccountDetailsChange } from "./components/AccountDetailsChange/AccountDetailsChange";
import { AccountDetailsPlan } from "./components/AccountDetailsPlan/AccountDetailsPlan";
import { AccountDetailsPlanChoice } from "./components/AccountDetailsPlanChoice/AccountDetailsPlan";
import { getUserDetails } from "./api";
import { listOfSubscriptionNames } from "../../common/envConfig";
import axios from "axios";
import { functionBaseUrl } from "../../common/firebase";
import Swal from "sweetalert2";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { ForesightPayment } from "./components/ForesightPayment/ForesightPayment";

export const AccountDetailsView = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    picture: "",
    subscription: {
      status: false,
      plan: "",
      planId: "",
      endDate: "",
      autoRenewal: false,
      type: "Coach",
    },
  });
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [partnerSubData, setPartnerSubData] = useState(null);
  const [view, setView] = useState("details");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await getUserDetails();
      setUserData({
        name: res.userName,
        email: res.email,
        picture: res.picture,
        subscriptionType: res.subscriptionType,
        secondarySubType: res?.secondarySubType,
        secondarySubPlan: res?.secondarySubType
          ? listOfSubscriptionNames[res?.secondarySubType]
          : "",
        userId: res.id,
        subscription: {
          status: res?.subscriptionType?.includes("free")
            ? true
            : res.sub?.endDate > Date.now(),
          plan:
            res?.subscriptionType?.includes("enterprise") &&
            res?.ent?.autoRenewal === false
              ? "Enterprise Staff"
              : listOfSubscriptionNames[res?.subscriptionType],
          planId: res?.subscriptionType,
          endDate: res?.subscriptionType?.includes("free")
            ? ""
            : moment(+res.sub?.endDate).format("MM-DD-YYYY"),
          endDateInMS: res?.subscriptionType?.includes("free")
            ? ""
            : res.sub?.endDate,
          autoRenewal: res?.subscriptionType?.includes("free")
            ? ""
            : res.sub?.autoRenewal,
          type: res?.subscriptionType?.includes("enterprise")
            ? "Enterprise"
            : res?.subscriptionType?.includes("studio")
            ? "Studio"
            : res?.subscriptionType?.includes("free")
            ? "Free"
            : res?.subscriptionType?.includes("student")
            ? "Student"
            : "Coach",
          platform: res.sub?.platform,
          transactionId: res.sub?.transactionId,
          id: res.sub?.id,
          startDate: moment(+res.sub?.startDate).format("MM-DD-YYYY"),
          amount: res.sub?.amount,
        },
        partnerSub: {
          status: res?.partnerSub?.endDate > Date.now(),
          plan: listOfSubscriptionNames[res?.partnerSub?.productId],
          planId: res?.partnerSub?.productId,
          endDate: moment(+res?.partnerSub?.endDate).format("MM-DD-YYYY"),
          endDateInMS: res?.partnerSub?.endDate,
          autoRenewal: res?.partnerSub?.autoRenewal,
          transactionId: res?.partnerSub?.transactionId,
          id: res?.sub?.id,
          startDate: moment(+res?.partnerSub?.startDate).format("MM-DD-YYYY"),
          amount: res?.sub?.amount,
        },
      });
      setSubscriptionData(res.sub);
      setPartnerSubData(res?.partnerSub);

      const getSession = async (sessionId) => {
        try {
          const response = await axios(
            `${functionBaseUrl}/api/stripeSession/${sessionId}`
          );
          const setupIntent = response.data.setup_intent;
          await axios(`${functionBaseUrl}/api/stripeUpdatePaymentMethod`, {
            method: "POST",
            data: { email: res.email, setupIntent },
          });
          Swal.fire({
            title:
              '<p style="font-size:70%;">Card details successfully updated</p>',
            icon: "success",
            confirmButtonText: "Ok",
            allowOusideClick: false,
          });
        } catch (error) {
          console.error(error);
          Swal.fire({
            title: '<p style="font-size:70%;">Error updating card details</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            timer: 3000,
          });
        }
      };

      const query = new URLSearchParams(window.location.search);

      if (query.get("session_id")) {
        getSession(query.get("session_id"));
      }

      if (query.get("view")) {
        setView(query.get("view"));
      }

      setIsLoading(false);
    })();
  }, []);

  const viewSwitcher = () => {
    switch (view) {
      case "plan-details":
        return (
          <>
            <AccountDetailsPlanChoice
              email={userData.email}
              userId={userData.userId}
              type={userData.subscription.type}
              subscriptionData={subscriptionData}
              fullName={userData.name}
              currentPlan={userData.subscription.planId}
              onViewChange={() => setView("plan")}
              onSubscriptionPurchase={() => setView("details")}
            />
          </>
        );

      case "plan":
        return (
          <>
            <AccountDetailsPlan
              email={userData.email}
              userId={userData.userId}
              subscriptionType={userData?.subscriptionType}
              secondarySubType={userData?.secondarySubPlan}
              status={userData.subscription.status}
              plan={userData.subscription.plan}
              autoRenewal={userData.subscription.autoRenewal}
              endDate={userData.subscription.endDate}
              endDateInMS={userData.subscription.endDateInMS}
              type={userData.subscription.type}
              platform={userData.subscription.platform}
              transactionId={userData.subscription.transactionId}
              subscriptionId={userData.subscription.id}
              startDate={userData.subscription.startDate}
              amount={userData.subscription.amount}
              partnerSubData={partnerSubData}
              onViewChange={(val) => setView(val)}
            />
          </>
        );

      case "foresight-payment":
        return (
          <ForesightPayment
            email={userData.email}
            fullName={userData.name}
            userId={userData.userId}
          />
        );

      default:
        return (
          <>
            <AccountDetailsChange
              email={userData.email}
              name={userData.name}
              picture={userData.picture}
            />
          </>
        );
    }
  };

  return (
    <div className={s.header}>
      <h1>Details</h1>
      <h2>Make changes to your profile</h2>
      <hr />
      <AccountDetailsTabs
        selectedView={view}
        onChange={(val) => setView(val)}
      />

      {isLoading ? <CircularProgress /> : viewSwitcher()}
    </div>
  );
};
