import React, { useEffect, useState } from "react";
import s from "./styles.module.css";
import { ButtonV2 } from "../../../../components/redesign-components/ButtonV2/ButtonV2";
import EditIcon from "@material-ui/icons/Edit";
import { StripeUpdatePayment } from "../../../../components/stripeUpdatePayment";
import Swal from "sweetalert2";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";
import { getCardNumber } from "../../api";
import moment from "moment";

export const AccountDetailsPlan = ({
  onViewChange,
  status,
  subscriptionType,
  secondarySubType,
  plan,
  autoRenewal,
  endDate,
  endDateInMS,
  type,
  platform,
  email,
  transactionId,
  userId,
  subscriptionId,
  startDate,
  amount,
  partnerSubData,
}) => {
  const [showStripe, setShowStripe] = useState(false);
  const [cardData, setCardData] = useState({ lastFour: "", brand: "" });

  useEffect(() => {
    (async () => {
      const res = await getCardNumber(email);
      if (res?.paymentMethods?.data?.length > 0) {
        setCardData({
          brand: res.paymentMethods.data[0].card.brand.toUpperCase(),
          lastFour: res.paymentMethods.data[0].card.last4,
        });
      }
    })();
  }, [email]);

  const handlePlanChange = async (productId) => {
    if (platform === "Apple" || platform === "Google") {
      await Swal.fire({
        title:
          '<p style="font-size:70%;">To cancel your subscription, please go to the platform that you originally subscribed from</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      });
      return false;
    }
    if (productId?.includes('foresight') ? partnerSubData?.autoRenewal : autoRenewal) {
      await Swal.fire({
        title:
          '<p style="font-size:70%;">Are you sure you want to cancel your subscription?</p>',
        icon: "warning",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "red",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (productId?.includes('foresight')) {
            await axiosWithToken(
              functionBaseUrl +
                "/api/pausePartnerSub/" +
                userId,
              {
                method: "POST",
              }
            )
              .then(async () => {
                await Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription successfully canceled</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                  customClass: {
                    container: "my-swal",
                  },
                }).then(() => {
                  window.location.reload();
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            await axiosWithToken(
              functionBaseUrl +
                (platform === "Elavon"
                  ? "/api/cancelElavonTxn/"
                  : "/api/pauseStripeSub/") +
                userId,
              {
                method: "POST",
              }
            )
              .then(async () => {
                await Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription successfully canceled</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                  customClass: {
                    container: "my-swal",
                  },
                }).then(() => {
                  window.location.reload();
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          return false;
        }
      });
    } else {
      await Swal.fire({
        title:
          '<p style="font-size:70%;">Are you sure you want to resume your subscription?</p>',
        icon: "warning",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "red",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (productId?.includes('foresight')) {
            await axiosWithToken(
              functionBaseUrl +
                "/api/resumePartnerSub/" +
                userId,
              {
                method: "POST",
              }
            )
              .then(async () => {
                await Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription successfully canceled</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                  customClass: {
                    container: "my-swal",
                  },
                }).then(() => {
                  window.location.reload();
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            if (platform === "Elavon") {
              await axiosWithToken(functionBaseUrl + "/api/activateElavonTxn", {
                method: "POST",
                data: {
                  ssl_recurring_id: transactionId,
                  ssl_billing_cycle: amount > 200 ? "ANNUALLY" : "MONTHLY",
                  ssl_next_payment_date: endDate,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then(async (response) => {
                  await axiosWithToken(
                    functionBaseUrl + "/api/subscription/" + subscriptionId,
                    {
                      method: "patch",
                      data: { autoRenewal: true },
                    }
                  )
                    .then(() => {
                      Swal.fire({
                        title:
                          '<p style="font-size:70%;">Subscription successfully activated</p>',
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                        icon: "success",
                        timer: 2000,
                        customClass: {
                          container: "my-swal",
                        },
                      }).then(() => {
                        window.location.reload();
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  Swal.fire(
                    '<p style="font-size:70%;">There was an error activating your subscription, please try again</p>'
                  );
                });
            }
            if (platform === "Stripe") {
              await axiosWithToken(
                functionBaseUrl + "/api/resumeStripeSub/" + userId,
                {
                  method: "POST",
                }
              )
                .then(async () => {
                  await Swal.fire({
                    title:
                      '<p style="font-size:70%;">Subscription successfully resumed</p>',
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    customClass: {
                      container: "my-swal",
                    },
                  }).then(() => {
                    window.location.reload();
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        } else {
          return false;
        }
      });
    }
  };

  return (
    <>
      <div className={s.container}>
        <div>Your Membership</div>
        <div className={s.planHeader}>{type}</div>

        {!subscriptionType.includes("free") && (
          <>
            <hr />

            <div className={s.segmentContainer}>
              <div className={s.segmentColumn}>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Status</div>
                  <div className={s.segmentLabel}>
                    {status ? "Active" : "Expired"}
                  </div>
                </div>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Current plan</div>
                  <div className={s.segmentLabel}>{plan}</div>
                </div>
                {secondarySubType && (
                  <div className={s.segmentItem}>
                    <div className={s.segmentLabel}>Secondary plan</div>
                    <div className={s.segmentLabel}>{secondarySubType}</div>
                  </div>
                )}
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Auto-renewal</div>
                  <div className={s.segmentLabel}>
                    {autoRenewal ? "On" : "Off"}
                  </div>
                </div>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>End date</div>
                  <div className={s.segmentLabel}>{endDate}</div>
                </div>
                {cardData.brand && cardData.lastFour && (
                  <div className={s.segmentItem}>
                    <div className={s.segmentLabel}>Payment</div>
                    <div className={`${s.cardNumber} ${s.segmentLabel}`}>
                      <div>
                        {cardData.brand} •••• •••• •••• {cardData.lastFour}
                      </div>

                      <EditIcon onClick={() => setShowStripe(true)} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className={s.buttonGroup}>
          <ButtonV2
            onClick={() => onViewChange("plan-details")}
            fit
            color={subscriptionType.includes("free") ? "purple" : "gray"}
            text={
              subscriptionType.includes("free")
                ? "Upgrade"
                : "Manage subscription"
            }
          />
          {transactionId && endDateInMS && !subscriptionType.includes('enterprise') && !subscriptionType.includes('studio') && (
            <ButtonV2
              fit
              color="gray"
              text={autoRenewal ? "Cancel plan" : "Resume plan"}
              onClick={() => handlePlanChange(subscriptionType)}
            />
          )}
        </div>
        {showStripe ? <StripeUpdatePayment subscriptionId={plan} /> : <></>}
      </div>
      <div className={s.divider} />
      {!subscriptionType?.includes('free') && !subscriptionType?.includes('studio') && <div className={s.container}>
        <div>Extra</div>
        <div className={s.planHeader}>Foresight Integration</div>
        {partnerSubData && (
          <>
            <hr />

            <div className={s.segmentContainer}>
              <div className={s.segmentColumn}>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Status</div>
                  <div className={s.segmentLabel}>
                    {partnerSubData?.endDate > Date.now() ? "Active" : "Expired"}
                  </div>
                </div>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Current plan</div>
                  <div className={s.segmentLabel}>{partnerSubData?.plan}</div>
                </div>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>Auto-renewal</div>
                  <div className={s.segmentLabel}>
                    {partnerSubData?.autoRenewal ? "On" : "Off"}
                  </div>
                </div>
                <div className={s.segmentItem}>
                  <div className={s.segmentLabel}>End date</div>
                  <div className={s.segmentLabel}>{moment(+partnerSubData?.endDate).format("MM-DD-YYYY")}</div>
                </div>
                {cardData.brand && cardData.lastFour && (
                  <div className={s.segmentItem}>
                    <div className={s.segmentLabel}>Payment</div>
                    <div className={`${s.cardNumber} ${s.segmentLabel}`}>
                      <div>
                        {cardData.brand} •••• •••• •••• {cardData.lastFour}
                      </div>

                      <EditIcon onClick={() => setShowStripe(true)} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {(!partnerSubData || partnerSubData?.endDate < Date.now()) && <ButtonV2
          fit
          color="purple"
          text="Purchase"
          onClick={() => onViewChange("foresight-payment")}
        />}
        {partnerSubData?.transactionId && (
          <ButtonV2
            fit
            color="gray"
            text={partnerSubData?.autoRenewal ? "Cancel plan" : "Resume plan"}
            onClick={() => handlePlanChange(partnerSubData?.productId)}
          />
        )}
      </div>}
    </>
  );
};
